nav.navbar.navbar-default{
  margin-bottom: 0;
}
.navbar-brand{
  padding:5px 5px;
}
.navbar{
  margin-bottom: -20px;
}

.navbar-default .navbar-nav>li>a{
  color: #D7222A;
  font-family: 'Arial Black';
  font-size: 16px;
}

.navbar-default .navbar-toggle{
  border: none;
}
button.navbar-toggle.collapsed{
  background-color: #D7222A;
}
.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover{
  background-color: #D7222A;
}

body > nav > div > div.navbar-header > button.navbar-toggle:hover{
  background-color: none;
}
.navbar-default .navbar-toggle span.icon-bar{
  background-color:#FFFFFF;
}



  .landing-page{
      background-image: url("/images/workers.jpg");
      width:100%;
      height:65em;
        form{
          margin-top:10em;
          background-color: #D7222A;
          padding:2em 1em 3em;
          h1{
            color:#ffffff;
            font-family: 'Arial Black';
          }
          h2{
            color:#ffffff;
            font-size: 24px;
            margin-bottom: 1em;
            font-family: 'Arial Black';
            line-height:1.2em;
          }
          section{
            background-color: #ffffff;
            height: .2em;
            margin-bottom:2em;
          }
          span{
            input{
              width:100%;
              margin-bottom: 1em;
              padding:.5em;
              border:none;
              text-align: center;
              color:#000000;
            }
            button{
              background-color: #ffffff;
              color: #D7222A;
              width: 100%;
              padding:.5em;
              border:none;
              font-family: 'Arial Black';

            }
          }
        }
  }

      .landing-signup{
        padding: 4em 1em 22em;
        margin-top: 9em;
        background-color: #ffffff;
        h2{
          color:black;
          text-align: center;
          font-family: 'Arial Black';
        }
        button{
          background-color: #D7222A;
          border:none;
          padding:.5em;
          width:100%;
          a{
            text-decoration: none;
            color:#ffffff;
          }
        }
        section{
          background-color: #D7222A;
          height: .2em;
          margin:5em 4em 0em;
        }
      }



div.top-footer{
    background-color: #FAFAFA;
    padding-top:2em;
        .container{
            span{
              width:100%;
              display:inline-block;
              text-align: center;
              background-color: #A9A9A9;
              margin-top:.5em;
              a{
                color:#000000;
                text-decoration: none;
                display:inline-block;
                font-size:10px;
                padding:.5em;
                font-family: 'Arial Black';
                }
            }
            div.footer{
              padding-top:.5em;
              color: #D7222A;
              font-family: 'Arial Black';
              font-size:10px;
              text-align: center;
              }
            }
          }


div.container.sign-up{
        padding-bottom:16em;
      div.sign-up-form{
        background-color: #fff;
        padding: 1em;
        margin-top:5em;
      }
      h2{
        font-family: 'Arial Black';
        margin-bottom:1em;
      }
      #contentsec{
            form.signup{
              input{
                border: solid 2px #000000;
                padding: .5em;
                width: 100%;
                margin-bottom: .5em;
              }
              p.check{
                padding-top: .5em;
                input.terms{
                  width: 10%;
                }
                a{
                  color: #D7222A;
                }
              }
              .button{
                background-color:#D7222A;
                border: none;
                color: white;
                font-family:  'Arial Black';
                width:100%;
                padding:.5em;
              }
            }
          }
      }


div.contact{
        padding-bottom:16em;
        div.contact-form{
            background-color: #FFFFFF;
            margin: 2em 0em 0em;
            padding: 1em;
            margin-top:5em;
            h2{
                font-family: 'Arial Black';
                margin-bottom:1em;
              }
            p > a{
                color: #D7222A;
              }
            }
        form > table{
              width: 100%;
                  td > div.field > p{
                      input#name{
                          border: solid 2px #000000;
                          padding: .5em;
                          width: 100%;
                        }
                      input#email{
                          border: solid 2px #000000;
                          padding: .5em;
                          width: 100%;
                        }
                      input#subject{
                          border: solid 2px #000000;
                          padding: .5em;
                          width: 100%;
                        }
                      textarea#message{
                          height: 10em;
                          border: solid 2px #000000;
                          padding: .5em;
                          width: 100%;
                        }
                      input#send.button.is-primary{
                          color:white;
                          background-color: #D7222A;
                          border: none;
                          font-family: 'Arial Black';
                          padding: .5em;
                          width:100%;
                        }
                      }
                    }
            }

div.unsubscribe{
        padding-bottom:22em;
            div.unsubscribe-form{
                background-color: #FFF;
                margin: 2em 0em 0em;
                padding: 1em;
                margin-top:5em;
              h2{
                font-family: 'Arial Black';
                margin-bottom:1em;
                }
              #contentsec{
                padding-bottom: 10px;
                margin-bottom: -40px;
                  p{
                    background-color: white;
                    padding: .5em 0em .5em;
                    }
                  form.unsubscribe{
                    padding:.5em;
                    border: solid 2px #000000;
                  label{
                    background-color: white;
                    padding-left: 1em;
                    padding-top: 1em;
                  }
                  input.button{
                    background-color:#D7222A;
                    margin-left: .5em;
                    margin-top: 1em;
                    margin-bottom: 1em;
                    padding:.5em;
                    width:90%;
                    color: white;
                    font-family: 'Arial Black';
                    border:none;
                  }
                  input.text{
                    border: solid 2px #000000;
                    width: 90%;
                    padding: .5em;
                    margin-top: -8px;
                    margin-left: .5em
                    }
                  }
                }
              }
          }

div.email-policy{
        margin-bottom:4em;
          div.email{
              margin: 2em 0em 17em;
              padding: 1em;
            h2{
              font-family: 'Arial Black';
              }
            #contentsec{
              .text-box{
                div.alert-msg.warning{
                  a{
                    color: #D7222A;
                    }
                }
                p{
                  a{
                    color: #D7222A;
                    }
                  }
              }
        }
    }
  }

div.privacy-policy{
      margin-bottom:4em;
        div.privacy{
          background-color: #FFF;
          margin: 2em 0em 17em;
          padding: 1em;
          h2{
            font-family: 'Arial Black';
          }
          section#contentsec{
            h3.accordion.active{
              background-color:#000000;
              color:#ffffff;
              font-family: 'Arial Black';
            }
            div:nth-child(21) > p > a{
              color: #D7222A !important;
            }
            div:nth-child(31) > p > a{
              color: #D7222A !important;
            }
          }
      }
    }

div.terms-of-use{
      margin-bottom:4em;
      div.terms{
        background-color: #FFF;
        margin: 2em 0em 17em;
        padding: 1em;
        h2{
          font-family: 'Arial Black';
        }
        section#contentsec{
          h3.accordion.active{
            background-color:#000000;
            color:#ffffff;
            font-family: 'Arial Black';
          }
        }
      }
    }


    div.unsubscribe-success{
          width: 100%;
          margin-bottom:4em;
          background-color: #FFF;
          margin: 2em 0em 17em;
          padding: 1em;
          div.content{
            h2{
              font-family: 'Arial Black';
            }
            a{
              color: #D7222A;
            }
          }
          form{
            div.Keywords{
              input[type="text"]{
                border:solid 2px #000000;
              }
            }
            div.Location > input[type="text"]{
              border:solid 2px #000000;
            }
            div.Submit{
              color:white;
              background-color: #D7222A;
              font-family: 'Arial Black';
              padding-left:0px !important;
              padding-top:0px !important;
            }
          }
        }


        div.post{
            margin: 2em 1em 12em;
            padding: 1em;
            h1{
              color: #D7222A;
              margin-bottom:1em;
              padding-bottom:.5em;
              border-bottom: 4px solid;
            }
          }
          div.page{
            text-align: center;
            a.pagination{
            color: #D7222A;
            margin-top:1em;
            }
        }
        a.post-links{
          text-decoration:none;
              div.individual-post{
                border-bottom: solid 1px #D7222A;
              }
              p.title{
                font-size: 26px;
                font-weight: bold;
                margin-top:1em;
                color:#3D3D3D;
              }
              p.summary{
                color:#3D3D3D;
                font-size: 16px;
              }
              button.continue {
                color: #D7222A;
                padding-bottom: 1em;
                background-color: white;
                border: none;
              }
          }


        div.blog-page{
          margin-top: 2em;
          margin-bottom:4em;
          h2{
            color: #D7222A;
          }
          a.back-to-posts{
            color:#D7222A;
            text-decoration: none;
          }
        }



@media(min-width:500px){
  .landing-page{
    form{
      width:70%;
      margin-left: 6em;
    }
  }

  .landing-signup{
      h2{
        width:70%;
        margin-left: 3em;
      }
      button{
        width:70%;
        margin-left: 6em;
      }
      section{
        width:70%;
        margin-left: 6em;
      }
  }
  div.post{
    padding:4em;
    margin: 0em 1em 12em;
  }
}

@media(min-width:768px){
  .navbar-right{
    margin-right: 0px;
  }
  .navbar-default .navbar-nav > li > a {
    font-size: 12px;
  }
  .landing-page{
    form{
      width:53%;
      margin-left: 11em;
    }
  }

  .landing-signup{
      h2{
        width:66%;
        margin-left: 4.5em;
        padding-bottom:1em;
      }
      button{
        width:60%;
        margin-left: 11em;
      }
      section{
        width:60%;
        margin-left: 11em;
      }
  }

  div.container.sign-up{
    width:60%;

  }
  div.container.contact{
    width:60%;
  }
  div.unsubscribe{
    width:60%;
  }
  div.unsubscribe-success{
    div > div > form > div.Submit > input[type="submit"]{
      margin:0 !important ;
    }
  }
  div.individual-post{
    width:70%;
  }

}

@media(min-width:992px){
  .navbar-brand>img{
    padding-left: 4em;
  }
  .landing-page{
    form{
      width:50%;
      margin-left: 18em;
      margin-top: 20em;
      padding:2em;
      span{
        display: inline-block;
        button{
          width:125%;
        }
      }
    }
  }

  .landing-signup{
      h2{
        width:50%;
        margin-left: 8.5em;
      }
      button{
        width:40%;
        margin-left: 22em;
      }
      section{
        width:40%;
        margin-left: 22em;
      }
    }

    div.privacy-policy{
      width:80%;
    }

    div.email-policy{
      width:80%;
    }

    div.terms-of-use{
      width:80%;
    }
    div.unsubscribe-success{
      width:80%;
      margin:0 auto;
    }

    div.unsubscribe div.unsubscribe-form #contentsec form.unsubscribe{
      padding-left:2em;
    }
    div.top-footer{
        background-color: #FAFAFA;
        padding-top:2em;
            .container{
                span{
                  display:inline;
                  background-color:#FAFAFA;
                  a{
                  }
                }
                div.footer{
                  float:right;
                  }
                }
              }
      div.unsubscribe-success{
          padding-top:6em;
      }
    div.post{
      padding:4em 9em 4em;
    }
}


@media(min-width:1200px){
  .navbar-brand>img{
    padding-left: 9em;
  }
  .navbar-right{
    margin-right:110px;
  }
  .landing-page{
    form{
    padding-left:5em;
    padding-right:5em;
    margin-left:22em;
      span{
        button{
          width:150%;
        }
      }
    }
  }

  .landing-signup{
      h2{
        width:50%;
        margin-left: 10em;
      }
      button{
        width:40%;
        margin-left: 26em;
      }
      section{
        width:40%;
        margin-left: 26em;
      }
    }

    div.container.sign-up{
      padding-bottom:22em;
    }

    div.unsubscribe{
      padding-bottom:29em;
    }

    div.email-policy{
      margin-bottom: 11em;
    }

    div.post{
      padding:4em 15em 4em;
    }
    div.individual-post{
      width: 100%;
    }

}

@media(min-width:1500px){
  .navbar-right{
    padding-right: 15em;
  }

  .navbar-default .navbar-nav > li > a{
    font-size:15px;
  }

  .landing-page{
    form{
      width:43%;
      padding-left:7em;
      padding-right:7em;
      margin-left:36em;
      span{
        button{
          width:150%;
        }
      }
    }
  }

  .landing-signup{
      h2{
        width:50%;
        margin-left: 15em;
      }
      button{
        width:30%;
        margin-left: 44em;
      }
      section{
        width:28%;
        margin-left: 44em;
      }
    }
      div.container.sign-up{
        width:41%;
        margin-left: 36em;
        padding-bottom:31em;
      }

      div.container.unsubscribe{
        width:41%;
        margin-left: 36em;
        padding-bottom:38em;
      }

      div.container.contact{
        width:41%;
        margin-left: 36em;
        padding-bottom:19em;
      }

      div.email-policy{
        margin-bottom: 14em;
        width:50%;
        margin-top: 6em;
        margin-left: 27em;
      }

      div.privacy-policy{
        width: 50%;
        margin-top: 6em;
      }

      div.terms-of-use{
        width: 50%;
        margin-top: 6em;
      }

      div.unsubscribe-success{
          margin-left: 24em;
          width:60%;
      }
      div.post{
        padding: 4em 20em 4em;
        width:80%;
      }

}
